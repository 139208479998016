// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Watch } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";
import { VNode } from "vue/types/umd";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import permessi from "@/config/permessi";
import apiFile from "@/services/apiFile";
import { RigaCarrello } from "@/models/carrello/rigaCarrello";
import helpers from "@/helpers";

const _ENDPONT_MODIFICA_PRATICA_AVANZATA = api.endpoint.CARRELLO.MODIFICA_PRATICA_AVANZATA;

@Component({})
export default class ricercaGaranziePage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.MODIFICA_PRATICA_AVANZATA];
	}
	
	distributori: Array<TextValueItem> = [];
	pacchetti: Array<TextValueItem> = [];
	parametri: RigaCarrello = new RigaCarrello();

	praticaModificata: any = null;

	mounted() {
		this.parametri.adeguatezza = true; // uso campo adeguatezza per indicare se devo registrare la modifica
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI_UTENTI).then(res => (self.distributori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.PACCHETTI).then(res => (self.pacchetti = res))
		];

		Promise.all(p)
			.then(responses => {
				self.loading = false;
			})
			.catch(err => {
				console.log(err);
			});
	}


	aggiornaPratica() {
		var dati = helpers.cloneObject(this.parametri ),
			self = this;
		
		var p = this.parametri  as any;
		if(this.parametri.distributoreID && p.distributoreID.value ){
			dati.distributoreID = p.distributoreID.value;
			dati.userId = p.distributoreID.code;
		}
		else
			dati.distributoreID = 0;
		
		dati.pacchettoID = dati.pacchettoID && dati.pacchettoID.value ? dati.pacchettoID.value : 0
		
		api.chiamataPost(_ENDPONT_MODIFICA_PRATICA_AVANZATA, dati)
			.then(res => {
				self.$bvToast.toast("Pratica modificata correttamente e PDF rigenerati".toString(), {
					variant: "success",
					title: "Pratica modificata",
					solid: true
				});
				
				self.praticaModificata = res.data as any;
			})
			.catch(err => {
				// self.$bvToast.toast((err && err.response && err.response.data )|| self.$i18n.t("generico.erroreGenerico").toString(), {
				// 	variant: "error",
				// 	title: self.$i18n.t("generico.errore").toString(),
				// 	solid: true
				// });
			});
	}

	downloadAdesioneFile() {
		var guid = this.praticaModificata.itemGUID;
		var self = this;
		var url = `adesione/${guid}`;
		var nomeFile = "CertificatoAssicurativo.pdf";
		apiFile
			.downloadFile(url, nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile;
				link.click();
				URL.revokeObjectURL(link.href);

				setTimeout(() => {
					location.reload();
				}, 500);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}
}
